import React from 'react';
import Layout from '../components/Layout';

export default function TermsConditions() {
  return (
    <Layout changeColorWhileScroll={true}>
      <div
        className='w-full h-full py-16'
        style={{ minHeight: 'calc(100vh - 15rem)' }}
      >
        <div className='relative w-full h-full z-10 max-w-5xl px-5 mx-auto'>
          <div className='grid grid-cols-1 gap-5'>
            <h3 className='text-black font-bold text-xl lg:text-3xl text-center'>
              Terms and Conditions
            </h3>
            <ul className='list-item list-decimal' >
              <li className='py-2'>
                <label className='italic font-semibold'>Term of Use </label>
                <p>
                The content and design of this website is subject to copyright and licences owned by Medic. You may not copy the design of this website or use any of its content without first obtaining permission from Medic.
                </p>
              </li>
              <li className='py-2'>
                <label className='italic font-semibold'>Privacy Policy</label>
                <p>
                  Your data privacy is important to us. We are compliant with the General 
                  Data Protection Regulation (EU GDPR). Your personal data which includes sensitive 
                  personal data provided by you will be kept confidential and only used in relation 
                  to your application. Medic will always give you the option to opt in or out of any 
                  promotional material we send to you. Read the Medic <u className='font-bold'>Privacy and Security Statement </u> 
                   for more information.
                </p>
                <p className='py-2'>
                  Should you purchase a policy, the information you supply will be used by the 
                  underwriter of the policy (the ”Underwriter”) including its employees, 
                  representatives, reinsurers, corporate agents and affiliates to keep you 
                  informed of services or products which may be of interest to you. You have the 
                  right to access, correct or change any of your own personal data by contacting the 
                  respective Underwriter below.
                </p>
                <p className='py-2'>
                    This website is compliant with the privacy and security policies of the 
                    Underwriters. If you have any questions regarding the privacy or security of 
                    your information, please contact the Underwriters below.
                </p>
                
              </li>
              <li className='py-2'>
                <label className='italic font-semibold'>Underwritters</label>
                <p>
                    Medic underwrites the life insurance, total and permanent disability, 
                    critical illness,  accidental death and medical insurance policies offered.
                </p>
              </li>
              <li className='py-2'>
                <label className='italic font-semibold'>Underwritters</label>
                <p>
                Medic underwrites the life insurance, total and permanent disability, 
                critical illness,  accidental death and medical insurance policies offered.
                </p>
              </li>
              <li className='py-2'>
                <label className='italic font-semibold'>Advise Statement</label>
                <p>
                      The information on this website does not take into account your personal 
                      financial needs, objectives or circumstances. Medic provides you with general 
                      information which should not be considered as advice. 
                      Please refer to the product disclosure statement before deciding on 
                      your purchase of this plan.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}
