export default function toRinggit(amount = 0) {
  let formattedAmount = amount.toLocaleString("en-MY", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes(".")) {
    const parts = formattedAmount.split(".");
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    formattedAmount = integerPart + "." + parts[1];
  }

  return "RM" + formattedAmount;
}

export function currency(amount = 0) {
  let formattedAmount = amount.toLocaleString("en-MY");
  return formattedAmount;
}

export function commaFormatted(amount) {
  if (amount === undefined || amount === null) {
      return ''; // Handle undefined or null input
  }

  // Convert amount to string if it's not already
  amount = amount.toString();

  // Split the amount into integer and decimal parts
  const [integerPart, decimalPart] = amount.split('.');

  // Add commas to the integer part
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // If there is a decimal part, append it to the formatted integer part
  if (decimalPart) {
      return `${formattedIntegerPart}.${decimalPart}`;
  } else {
      return formattedIntegerPart;
  }
}

export function digitWithoutCommaFormatted(value){
  return value.replace(/,/g, '');
}
