export const mockSliders = [
  {
    bgImg: '/images/about-us/about-us.png',
    title: 'Dream Big. Achieve Bigger',
    desc: 'Trade with a Global Broker to achieve your investment goals.',
    srcImg: '/images/BitCoin.png',
    altImg: 'Bit Coin',
  },
  {
    bgImg: '/images/about-us/about-us.png',
    title: 'Invest Better Together',
    desc: 'Get investment ideas from trusted broker & friendly platform.',
    srcImg: '/images/Investment.png',
    altImg: 'Invest',
  },
  {
    bgImg: '/images/KLCC-scaled.jpg',
    title: 'Trade Crypto',
    desc: 'With Malaysia Ringgit and 24/7 support.',
    srcImg: '',
    altImg: '',
  },
  {
    bgImg: '/images/Lisenced-Broker-scaled.jpg',
    title: 'Trade with a llisenced Broker',
    desc: 'Committed to Data Security & Quality Service.',
    srcImg: '',
    altImg: '',
  },
];

export const mockTrushted = [
  {
    title: 'Reliable',
    desc: 'Regulated by various authorities',
  },
  {
    title: 'Secured',
    desc: 'Utilising top-tier security practices',
  },
  {
    title: 'Social',
    desc: 'More than 10 million users',
  },
];

export const mockStarted = [
  {
    title: 'Sign up',
    desc: 'Create an account for free using your email address',
  },
  {
    title: 'Explore the platform',
    desc: "See what it's like to invest.",
  },
  {
    title: 'Invest & earn profit',
    desc: 'Select an asset and make your money work for you',
  },
];

export const mockInvest = [
  {
    crypto: 'Bitcoin',
    currency: 'RM140.12K',
    percent: '-1.76%',
    icon: '/svg/bitcoin-logo.svg',
    chart: '/svg/chart-btc.svg',
  },
  {
    crypto: 'Ethereum',
    currency: 'RM8.61K',
    percent: '-1.61%',
    icon: '/svg/ethereum-logo.svg',
    chart: '/svg/chart-eth.svg',
  },
  {
    crypto: 'Polkadot',
    currency: 'RM23.57',
    percent: '-2.93%',
    icon: '/svg/polkadot-logo.svg',
    chart: '/svg/chart-pkd.svg',
  },
  {
    crypto: 'Huobi',
    currency: 'RM12.59',
    percent: '-0.88%',
    icon: '/svg/huobi-logo.svg',
    chart: '/svg/chart-huobi.svg',
  },
  {
    crypto: 'XRP',
    currency: 'RM2.19',
    percent: '-1.96%',
    icon: '/svg/xrp-logo.svg',
    chart: '/svg/chart-xrp.svg',
  },
];

export const mockTestimoni = [
  {
    name: 'Lin Mei',
    testimoni: `Having your medical card accessible right on my phone is a game-changer! No more digging through piles of paperwork. 5 stars for convenience!`,
    date: '22/04/2018',
  },
  {
    name: 'Nur Farah',
    testimoni: 'Signing up online was a breeze! Quick, clear, and affordable. Now I can finally get the healthcare I need.',
    date: '16/11/2019',
  },
  {
    name: 'Siti Aisha',
    testimoni: `So grateful for Medic in my rural area. Finding specialists used to be a journey, now it''s just a few clicks away!`,
    date: '12/07/2020',
  },
  {
    name: 'Amirul',
    testimoni: 'My doctor visit was seamless with Medic. No hassle with billing, just focused on getting better. Thank you!',
    date: '30/01/2021',
  },
  {
    name: 'Priya',
    testimoni: 'I was nervous about using telehealth, but the doctor was amazing! So patient and thorough. Medic makes healthcare less intimidating.',
    date: '06/03/2021',
  },
  {
    name: 'Hao Yan',
    testimoni: 'My unexpected hospitalization went smoothly thanks to Medic. They handled everything, allowing me to concentrate on recovery.',
    date: "10/09/2021",
  },
  {
    name: 'Dayang Nur',
    testimoni: 'Best Forex Trading Platform in Malaysia.',
    date: '17/05/2022',
  },
  {
    name: 'Anjali',
    testimoni: 'Finally found an insurance plan that fits my budget! Medic offers amazing coverage without breaking the bank.',
    date: `05/02/2022`,
  },
  {
    name: 'Dayang Izzah',
    testimoni: `Investing in my health with Medic is my best decision yet. Peace of mind knowing I'm covered is priceless.`,
    date: '19/08/2022',
  },
  {
    name: 'Aravind',
    testimoni: `Love that Medic supports local healthcare providers! It's great to know my premiums are helping my community.`,
    date: '28/10/2022',
  },
  {
    name: 'Harith',
    testimoni: 'Their customer service is top-notch! Always friendly, helpful, and available to answer my questions. It feels like they truly care.',
    date: '09/12/2022',
  },
  {
    name: 'Dahlia',
    testimoni: `Medi goes beyond just insurance. Their educational resources and wellness programs are incredibly valuable.`,
    date: `24/06/2023`,
  },
  {
    name: 'Emelda',
    testimoni: `I used to put off going to the doctor because of finances. Now, with Medic, I prioritize my health without worries.`,
    date: `04/04/2023`,
  },
  {
    name: 'Jerome,',
    testimoni: `Medic gave me the confidence to finally address my chronic health condition. I feel empowered and in control.`,
    date: `15/10/2023`,
  },
  {
    name: 'Krishna,',
    testimoni: `My family's health is my top priority. Medic gives me the security and peace of mind to focus on what matters most.`,
    date: `31/07/2023`,
  },
  {
    name: 'Junaina,',
    testimoni: `The telemedicine option is a lifesaver for busy parents like me! Saves time and keeps my kids healthy.`,
    date: `21/05/2023`,
  },
  {
    name: 'Akmal,',
    testimoni: `I travel frequently for work, and Medic's national coverage gives me peace of mind everywhere I go.`,
    date: `01/03/2023`,
  },
  {
    name: 'Brinda,',
    testimoni: `Their online health tracker and appointment booking tools are super user-friendly! Makes managing my health easy and proactive.`,
    date: `11/01/2023`,
  },
  {
    name: 'Iskandar,',
    testimoni: `Medic is more than just an insurance company, they're healthcare partners. I wouldn't trust anyone else with my health.`,
    date: `25/09/2023`,
  },
  {
    name: 'Si Xuan',
    testimoni: `So happy I discovered Medic! Affordable, convenient, and reliable healthcare. Five stars all the way!`,
    date: `07/11/2023`,
  },
];

export const faqs = [
  {
    id: 1,
    header: "What is Lorem Ipsum?",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
  },
  {
      id: 2,
      header: "Where does it come from?",
      text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `
  },
  {
      id: 3,
      header: "Why do we use it?",
      text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`
  },
  {
      id: 4,
      header: "Where can I get some?",
      text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
  }
];
