/* eslint-disable no-unused-vars */
import React from 'react';
// import { ReactComponent as Polkadot } from '../../icon/polkadot-logo.svg';
// import { ReactComponent as Huobi } from '../../icon/huobi-logo.svg';
// import { ReactComponent as Ethereum } from '../../icon/ethereum-logo.svg';
// import { ReactComponent as Bitcoin } from '../../icon/bitcoin-logo.svg';
// import { ReactComponent as Ripple } from '../../icon/xrp-logo.svg';
import {
  // HiOutlineCreditCard,
  // HiOutlineCash,
  // HiOutlineDocumentSearch,
  // HiOutlineTrendingUp,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiOutlineLibrary,
  HiOutlineHome,
  HiCash,
} from 'react-icons/hi';

export const userMenu = [
  {
    key: 'dashboard',
    menu: 'Home',
    label: 'Dashboard',
    icon: null,
    subMenuItems: [],
    menuItems: [
      {
        key: 'dashboard',
        label: 'Dashboard',
        icon: <HiOutlineHome />,
        path: '/dashboard',
      },
    ],
  },
  // {
  //   key: 'investment',
  //   menu: 'Investment',
  //   label: 'Investment',
  //   icon: null,
  //   subMenuItems: [],
  //   menuItems: [
  //     {
  //       key: 'bitcoin',
  //       label: 'Bitcoin',
  //       icon: (
  //         <Bitcoin
  //           width={18}
  //           height={18}
  //           style={{
  //             backgroundColor: 'white',
  //             padding: '2px',
  //             borderRadius: '5px',
  //           }}
  //         />
  //       ),
  //       path: '/dashboard/bitcoin',
  //     },
  //     {
  //       key: 'eth',
  //       label: 'Ethereum',
  //       icon: (
  //         <Ethereum
  //           width={18}
  //           height={18}
  //           style={{
  //             backgroundColor: 'white',
  //             padding: '2px',
  //             borderRadius: '5px',
  //           }}
  //         />
  //       ),
  //       path: '/dashboard/ethereum',
  //     },
  //     {
  //       key: 'pol',
  //       label: 'Polkadot',
  //       icon: (
  //         <Polkadot
  //           width={18}
  //           height={18}
  //           style={{
  //             backgroundColor: 'white',
  //             padding: '2px',
  //             borderRadius: '5px',
  //           }}
  //         />
  //       ),
  //       path: '/dashboard/polkadot',
  //     },
  //     {
  //       key: 'Houbitoken',
  //       label: 'Houbitoken',
  //       icon: (
  //         <Huobi
  //           width={18}
  //           height={18}
  //           style={{
  //             backgroundColor: 'white',
  //             padding: '2px',
  //             borderRadius: '5px',
  //           }}
  //         />
  //       ),
  //       path: '/dashboard/houbitoken',
  //     },
  //     {
  //       key: 'Ripple',
  //       label: 'Ripple',
  //       icon: (
  //         <Ripple
  //           width={18}
  //           height={18}
  //           style={{
  //             backgroundColor: 'white',
  //             padding: '2px',
  //             borderRadius: '5px',
  //           }}
  //         />
  //       ),
  //       path: '/dashboard/ripple',
  //     },
  //   ],
  // },
  // {
  //   key: 'finance',
  //   menu: 'Finance',
  //   label: 'Finance',
  //   icon: null,
  //   subMenuItems: [],
  //   menuItems: [
  //     {
  //       key: 'Withdrawal',
  //       label: 'Withdrawal',
  //       icon: <HiOutlineCash />,
  //       path: '/dashboard/withdraw',
  //     },
  //     {
  //       key: 'Withdrawal History',
  //       label: 'Withdrawal History',
  //       icon: <HiOutlineDocumentSearch />,
  //       path: '/dashboard/withdraw-history',
  //     },
  //     // {
  //     //   key: 'Profit/Loss History',
  //     //   label: 'Profit/Loss History',
  //     //   icon: <HiOutlineTrendingUp />,
  //     //   path: '/dashboard/profit-loss-history',
  //     // },
  //     {
  //       key: 'Deposit History',
  //       label: 'Deposit History',
  //       icon: <HiOutlineCreditCard />,
  //       path: '/dashboard/deposit-history',
  //     },
  //   ],
  // },
  {
    key: 'account',
    menu: 'Account',
    label: 'Account',
    icon: null,
    subMenuItems: [],
    menuItems: [
      {
        key: 'Password',
        label: 'Password',
        icon: <HiOutlineLockClosed />,
        path: '/dashboard/password',
      },
      {
        key: 'Claim',
        label: 'Make Your Claim',
        icon: <HiCash />,
        path: '/dashboard/customer-claim-submission',
      },
      // {
      //   key: 'Profile Information',
      //   label: 'Profile Information',
      //   icon: <HiOutlineLibrary />,
      //   path: '/dashboard/profile-information',
      // },
    ],
  },
];
