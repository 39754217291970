import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import home_en from "./pages/translation/en/Home.json";
import home_my from "./pages/translation/my/Home.json";
import home_cn from "./pages/translation/cn/Home.json";
import criticalIllness_en from "./pages/translation/en/CriticalIllness.json";
import criticalIllness_my from "./pages/translation/my/CriticalIllness.json";
import criticalIllness_cn from "./pages/translation/cn/CriticalIllness.json";
import medicalCard_en from "./pages/translation/en/MedicalCard.json";
import medicalCard_my from "./pages/translation/my/MedicalCard.json";
import medicalCard_cn from "./pages/translation/cn/MedicalCard.json";
import testimonial_en from "./pages/translation/en/Testimonial.json";
import testimonial_my from "./pages/translation/my/Testimonial.json";
import testimonial_cn from "./pages/translation/cn/Testimonial.json";

i18next.use(initReactI18next).init({
  debug: false,
  fallbackLng: "en",
  lng: "en",
  supportedLngs: ["en", "my", "cn"],
  resources: {
    en: {
      translation: {
        home: home_en,
        criticalIllness: criticalIllness_en,
        medicalCard: medicalCard_en,
        testimonial: testimonial_en
      },
    },
    my: {
      translation: {
        criticalIllness: criticalIllness_my,
        medicalCard: medicalCard_my,
        testimonial: testimonial_my,
        home: home_my,
      },
    },
    cn: {
      translation: {
        criticalIllness: criticalIllness_cn,
        medicalCard: medicalCard_cn,
        testimonial: testimonial_cn,
        home: home_cn,
      },
    },
  },
});

export default i18next;
