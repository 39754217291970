
export const faqs = [
  {
    id: 1,
    header: "What is the critical illness insurance ?",
    text: "A critical illness insurance pays out a cash lump sum if you suffer a critical illness to help you replace lost income or help supplement funds when you are undergoing medical treatment."
  },
  {
      id: 2,
      header: "What are the most common critical illness?",
      text: "Cancer, stroke, heart attack and coronary artery bypass surgery comprise more than 80% of critical illness cases in Malaysia. However, to be ensure that you are comprehensively covered, Fi Life policies cover a total of 39 critical illnesses."
  },
  {
      id: 3,
      header: "Is critical illness insurance essential ?",
      text: "Critical illness insurance becomes more essential as you get older. Generally speaking, we recommend you consider it when you’re in your mid-thirties, or earlier, if you have family or close relatives with a history of early onset of critical illness."
  },
  {
      id: 4,
      header: "Can I buy a critical illness plan without buying a life insurance plan?",
      text: "With Fi Life, you can only get critical Illness coverage as a rider to your base life insurance policy. You can get critical illness cover of up to 50% of your life insurance cover, up to a maximum of RM250,000."
  },
  {
    id: 5,
    header: "How does Medic Life critical illness accelerated payout work",
    text: "If you had a RM1 million life insurance policy with a critical illness cover of RM250,000, should you suffer a critical illness, we will pay out RM250,000 to you first. But in the unfortunate event that you die or suffer total and permanent disability afterwards, we will pay you the balance of RM750,000, as the earlier payment of RM250,000 has already been “accelerated” to you."
  }
];