import React, {useState, useRef} from "react";
import '../css/accordian.css'
import { useTranslation } from "react-i18next";
import { faqs } from "../mock/criticalIllnessData";
import Layout from "../components/Layout";

const CriticalIllness = () => {

  const contentEl = useRef();
  const { t } = useTranslation();

  const [active, setActive] = useState(null);
  const handleToggle = (index) =>{
    if(active === index){
      setActive(null);
    }
    else {
      setActive(index);
    }
  }

  return (
    <Layout changeColorWhileScroll={true}>
      <div className="w-full about-us  h-full py-20 ">
        <div className="relative w-full h-full z-10 max-w-7xl px-5 mx-auto">
          <div
            id="heading-hero"
            className="flex flex-col items-center justify-center"
          >
            <h1 className="text-4xl lg:text-7xl font-bold text-center text-white uppercase">
              Critical Illness
            </h1>
            <Spacing />
          </div>
        </div>
      </div>
      <div className="w-full h-full py-24">
        <div className="relative w-full h-full z-10 px-5 max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 w-full ">
            <div >
                <div className="flex  flex-row py-10" >
                  <h3>{t(`criticalIllness.section1.title`)}</h3>
                </div>
                <div className="flex" >
                  <label> {t(`criticalIllness.section1.subtitle`)}</label>
                </div>
                <div className="flex" >
                  <ul className="list-disc">
                    <li>{t(`criticalIllness.section1.list.1`)} </li>
                    <li>{t(`criticalIllness.section1.list.2`)} </li>
                    <li>{t(`criticalIllness.section1.list.3`)}</li>
                  </ul>
                </div>
            </div>
            <div>
              <img src="/images/critical-illness/criticalIllness.png" alt="Critical Illness"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 py-10  bg-white">
      <div className="flex justify-center text-center">
        <h2>{t(`criticalIllness.faq.title`)}</h2>
      </div>
      <div className="flex flex-auto justify-center w-full">
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 w-full ml-10">
        <div className="" >
            <img className="lg:visible sm:invisible " src="/images/critical-illness/criticalIlness-qa.png" alt="section faq" />
          </div>
          <div className="container-fluid mt-5 mb-5 " >
                    <div className="row ">
                        <div className="col-md-8 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    {faqs.map((faq) => {
                                      const { id } = faq;
                                        return (
                                                  <div className="rc-accordion-card">
                                                  <div className="rc-accordion-header">
                                                      <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                                                          <h5 className="rc-accordion-title">{t(`criticalIllness.faq.${id}.header`)}</h5>
                                                          <i className="fa fa-chevron-down rc-accordion-icon"></i>
                                                      </div>
                                                  </div>
                                                  <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                                                      active === id
                                                          ? { height: contentEl.current.scrollHeight }
                                                          : { height: "0px" }
                                                  }>
                                                      <div className="rc-accordion-body">
                                                          <p className='mb-0'>{t(`criticalIllness.faq.${id}.text`)}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
        </div>
      </div>
     
    </div>
    <div className="flex flex-col justify-center w-full py-10">
          <div className="flex flex-row justify-center">
              <div>
                <h2>{t(`criticalIllness.section3.title`)}</h2>
              </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
              <div className="flex flex-col p-10">
                <div>
                  <img src="/images/critical-illness/qa1.png" alt="q&a1" />
                </div>
                <div className="italic bg-white p-1">
                    <h6>{t(`criticalIllness.section3.cards.1.question`)}</h6>
                </div>
                <div className="bg-white p-1">
                  <p>
                      {t(`criticalIllness.section3.cards.1.answer`)}
                  </p>
                </div>
                <div className="bg-white p-1">
                  <a className="underline hover:underline" href="#">{t(`criticalIllness.section3.readmore`)}</a>
                </div>
              </div>
              <div className="flex flex-col p-10" >
                <div >
                  <img src="/images/critical-illness/qa2.png" alt="q&a2" />
                </div>
                <div className="italic bg-white p-1">
                    <h6>{t(`criticalIllness.section3.cards.2.question`)}</h6>
                </div>
                <div className="bg-white p-1">
                  <p>
                  {t(`criticalIllness.section3.cards.2.answer`)}
                  </p>
                </div>
                <div className="bg-white p-1">
                  <a className="underline hover:underline" href="#">{t(`criticalIllness.section3.readmore`)}</a>
                </div>
              </div>
              <div className="flex flex-col p-10">
                <div >
                  <img src="/images/critical-illness/qa3.png" alt="q&a3" />
                </div>
                <div className="italic bg-white p-1">
                    <h6>{t(`criticalIllness.section3.cards.3.question`)}</h6>
                </div>
                <div className="bg-white p-1">
                  <p>
                  {t(`criticalIllness.section3.cards.3.answer`)}
                  </p>
                </div>
                <div className="bg-white p-1">
                  <a className="underline hover:underline" href="#">{t(`criticalIllness.section3.readmore`)}</a>
                </div>
              </div>
          </div>
      </div>
    </Layout>
  );
};

export default CriticalIllness;

function Spacing() {
  return <div className="pt-14 pb-5"></div>;
}
