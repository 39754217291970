import {React} from "react";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";
import HomeTestimoni from "../components/HomePage/HomeTestimoni";
// import HomeSignUp from "../components/HomePage/HomeSignUp";
// import HomeProduct from "../components/HomePage/HomeProduct";
import HomeQA from "../components/HomePage/HomeQA";
// mock data
//import { mockSliders } from "../mock/mockData";


const Home = () => {
  return (
    <Layout>
      <Carousel />
      <HomeTestimoni/>
      <HomeQA />
    </Layout>
  );
};

export default Home;
