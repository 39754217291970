import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import HomeMedicalBenefit from "../components/HomePage/HomeMedicalBenefit";

const CriticalIllness = () => {
  const { t } = useTranslation();

  return (
    <Layout changeColorWhileScroll={true}>
      <div className="w-full about-us  h-full py-20 ">
        <div className="relative w-full h-full z-10 max-w-7xl px-5 mx-auto">
          <div
            id="heading-hero"
            className="flex flex-col items-center justify-center"
          >
            <h1 className="text-4xl lg:text-7xl font-bold text-center text-white uppercase">
              Medical Card
            </h1>
            <Spacing />
          </div>
        </div>
      </div>
      <div className="w-full h-full py-24">
        <div className="relative w-full h-full z-10 px-5 max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 w-full ">
            <div>
              <div className="flex  flex-col py-10">
                <h3>{t(`medicalCard.section1.title`)}</h3>
              </div>
              <div className="flex flex-col">
                <ul className="list-disc">
                  <li>{t(`medicalCard.section1.list.1`)} </li>
                  <li>{t(`medicalCard.section1.list.2`)} </li>
                  <li>{t(`medicalCard.section1.list.3`)}</li>
                  <li>{t(`medicalCard.section1.list.4`)}</li>
                </ul>
              </div>
              <div className="flex flex-auto justify-start">
                <div>
                  <button className="border bg-orange-400 px-12 py-2 rounded-full ">
                    {t(`medicalCard.section1.getquote`)}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <img
                src="/images/medical-card/freeflushot.png"
                alt="Free Flu Shot"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-16 bg-white justify-center">
        <div className="flex justify-center text-center font-bold text-5xl py-20">
          {t(`medicalCard.section2.title`)}
        </div>
        <div className="hidden sm:block">
          <div className="flex justify-center">
            <table class="table-auto border rounded  w-10/12 text-center">
              <thead className="">
                <tr className="h-40 bg-blue-300">
                  <th className="border">Benefit</th>
                  <th className="border ">MedX Plus Plan 1</th>
                  <th className="border">MedX Plus Plan 2</th>
                  <th className="border">MedX Plus Plan 3</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-20">
                  <td className="border">Overall Annual Limit</td>
                  <td className="border">RM 3,500,000</td>
                  <td className="border">RM 2,000,000</td>
                  <td className="border">RM 1,500,000</td>
                </tr>
                <tr className="h-20">
                  <td className="border">Room & Board</td>
                  <td className="border"> RM 650 </td>
                  <td className="border"> RM 400 </td>
                  <td className="border"> RM 200 </td>
                </tr>
                <tr className="h-20">
                  <td className="border">Outpatient Benefits</td>
                  <td className="border">Full Reimbursement</td>
                  <td className="border">Full Reimbursement</td>
                  <td className="border">Full Reimbursement</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="lg:hidden md:hidden">
          <HomeMedicalBenefit />
        </div>
      </div>
      <div className="flex flex-col justify-center w-full py-10">
        <div className="flex flex-row justify-center">
          <div>
            <h2>{t(`medicalCard.section3.title`)}</h2>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          <div className="flex flex-col p-10">
            <div>
              <img src="/images/medical-card/qa1.png" alt="q&a1" />
            </div>
            <div className="italic bg-white p-1">
              <h6>{t(`medicalCard.section3.cards.1.question`)}</h6>
            </div>
            <div className="bg-white p-1">
              <p>{t(`medicalCard.section3.cards.1.answer`)}</p>
            </div>
          </div>
          <div className="flex flex-col p-10">
            <div>
              <img src="/images/medical-card/qa2.png" alt="q&a2" />
            </div>
            <div className="italic bg-white p-1">
              <h6>{t(`medicalCard.section3.cards.2.question`)}</h6>
            </div>
            <div className="bg-white p-1">
              <p>{t(`medicalCard.section3.cards.2.answer`)}</p>
            </div>
          </div>
          <div className="flex flex-col p-10">
            <div>
              <img src="/images/medical-card/qa3.png" alt="q&a3" />
            </div>
            <div className="italic bg-white p-1">
              <h6>{t(`medicalCard.section3.cards.3.question`)}</h6>
            </div>
            <div className="bg-white p-1">
              <p>{t(`medicalCard.section3.cards.3.answer`)}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CriticalIllness;

function Spacing() {
  return <div className="pt-14 pb-5"></div>;
}
