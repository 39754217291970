import React, { useRef, useState } from "react";
import '../../css/accordian.css'
import { useTranslation } from "react-i18next";
import { faqs } from "../../mock/mockData";

const HomeQA = () => {
  const contentEl = useRef();
  const { t } = useTranslation();

  const [active, setActive] = useState(null);
  const handleToggle = (index) =>{
    if(active === index){
      setActive(null);
    }
    else {
      setActive(index);
    }
  }

  return (
    <>
    <div className="grid grid-cols-1 py-10  bg-white">
      <div className="flex justify-center text-center">
        <h2>{t(`home.faq.title`)}</h2>
      </div>
        <div className="flex flex-row w-full justify-center items-center">
          <div className="lg:block md:hidden sm:hidden w-10/12" >
            <img src="/images/section_faq.png" alt="section faq" />
          </div>
          <div className="container-fluid mt-5 mb-5 " >
                    <div className="row justify-content-center">
                        <div className="col-md-9 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    {faqs.map((faq) => {
                                      const { id } = faq;
                                        return (
                                                  <div className="rc-accordion-card">
                                                  <div className="rc-accordion-header">
                                                      <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                                                          <h5 className="rc-accordion-title">{t(`home.faq.${id}.header`)}</h5>
                                                          <i className="fa fa-chevron-down rc-accordion-icon"></i>
                                                      </div>
                                                  </div>
                                                  <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                                                      active === id
                                                          ? { height: contentEl.current.scrollHeight }
                                                          : { height: "0px" }
                                                  }>
                                                      <div className="rc-accordion-body">
                                                          <p className='mb-0'>{t(`home.faq.${id}.text`)}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
          
        </div>
      
    </div>
    </>
  );
} 


export default HomeQA;