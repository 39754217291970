import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
// components
import { FormProvider, RHFInputAddon } from "../../components/RHForm";
import ButtonLp from "../../components/ButtonLp";
import { enqueueSnackbar } from "notistack";
import useAuth from "../../hooks/zustand/useAuth";

const ForgotPassword = () => {
  const [submited, setSubmit] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const countryCode = "+60"
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Phone is required")
      .matches(/^\d+$/, "Number only!")
      .min(9, "Minimum 9 digit numbers")
      .max(13, "Maximum 13 digit numbers"),
  });
  const defaultValues = {
    phone: "",
  };
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { watch, handleSubmit, reset } = methods;
  const values = watch();
  const onSubmit = async () => {
    try {
      setSubmit(true);
      const res = await auth.checkCredentials({ phoneNumber: values.phone });
      if (!res.isExist) {
        enqueueSnackbar("Number not found in our app.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        reset();
        setSubmit(false);
      }
      if (res.isExist) {
        setSubmit(true);
        window.phoneNumber =  values.phone;
        window.countryCode =  countryCode.substring(1);
        console.log("phone number", window.phoneNumber);
        navigate("/reset-password");
      }
    } catch (error) {
      enqueueSnackbar("An error occured.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setSubmit(false);
      console.error("An error occured");
    }
  };

  return (
    <div
      className="w-full min-h-screen flex items-center"
      style={{
        backgroundImage: "url('/images/login-bg.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="w-full px-5 overflow-hidden"
      >
        <div className="w-full lg:w-[30rem] bg-white overflow-hidden rounded-lg shadow-lg px-5 lg:px-10 py-10 lg:py-14 mx-auto">
          <div className="flex flex-col items-center">
            <img
              src={process.env.REACT_APP_LOGO_PATH}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-36 lg:w-40 object-contain object-center"
            />
            <h1 className="text-xl lg:text-2xl font-semibold mt-10 mb-2">
              Recover Your Password
            </h1>
            <h2 className="text-sm lg:text-md font-semibold text-gray-400 text-center mb-2.5">
              Already have an account?{" "}
              <Link to="/sign-in" className="text-blue-500">
                Sign in here
              </Link>
            </h2>
          </div>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 lg:gap-8 w-full mx-auto">
              <div id="recaptcha-container" />
              <RHFInputAddon name="phone" label="Phone" addon={countryCode} />
              <ButtonLp
                type="submit"
                className="w-40 mx-auto"
                loading={submited}
              >
                Continue
              </ButtonLp>
            </div>
          </FormProvider>
          <div className="mt-5 text-center">
            <Link to="/" className="font-semibold text-blue-500 text-sm">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
