import React from "react";
import Layout from "../components/Layout";

const ClaimSubmission = () => {
  return (
    <Layout changeColorWhileScroll={true}>
      <div className="w-full about-us  h-full py-20 ">
        <div className="relative w-full h-full z-10 max-w-7xl px-5 mx-auto">
          <div
            id="heading-hero"
            className="flex flex-col items-center justify-center"
          >
            <h1 className="text-4xl lg:text-7xl font-bold text-center text-white">
              Claim Submission
            </h1>
            <Spacing />
          </div>
        </div>
      </div>
      <div className="w-full h-full py-24">
        <div className="flex flex-col justify-center items-center text-center">
             <img src="/images/underconstruction.png" className="max-w-full h-auto" />
             <label className="font-bold">Under Construction !!!</label>
             <label className="italic mt-4">We're working on something awesome!</label>
        </div>
      </div>
    </Layout>
  );
};

export default ClaimSubmission;

function Spacing() {
  return <div className="pt-14 pb-5"></div>;
}
