import Button from './Button';
import Paragraph from '../typography/Paragraph';
import { HiOutlineLogout } from 'react-icons/hi';

export default function ButtonLogout({ open, action = () => {} }) {
  return (
    <Button className="w-5/6 h-10 " radius='low' onClick={action}>
      {open ? (
        <Paragraph
          className={`font-semibold capitalize ${
            open ? 'opacity-100' : 'opacity-0'
          } duration-300 text-lg `}
        >
          Logout
        </Paragraph>
      ) : (
        <span
          className={`text-xl flex text-white w-full justify-center items-center py-2 px-2 ${
            open ? 'opacity-0' : 'opacity-100'
          } duration-300`}
        >
          <HiOutlineLogout />
        </span>
      )}
    </Button>
  );
}
