import React, { useEffect, useMemo } from "react";
import Layout from "../components/Layout";

const AboutUs = () => {
  return (
    <Layout changeColorWhileScroll={true}>
      <div className="w-full about-us  h-full py-20 ">
        <div className="relative w-full h-full z-10 max-w-7xl px-5 mx-auto">
          <div
            id="heading-hero"
            className="flex flex-col items-center justify-center"
          >
            <h1 className="text-4xl lg:text-7xl font-bold text-center text-white">
              Medic: Your Caring Community Compass Through Life's Uncertainties
            </h1>
            <Spacing />
          </div>
        </div>
      </div>
      <div className="w-full h-full py-24">
        <div className="relative w-full h-full z-10 px-5 max-w-7xl mx-auto">
          <p className="py-3">
            We understand. Life in Malaysia is beautiful, but unexpected
            challenges can sometimes throw you off course. At Medic, we're not
            just an insurance company – we're your neighbours, your friends,
            your community support system. We're here to ensure that the
            unexpected doesn't derail your dreams, especially for those of us
            working hard to make ends meet.
          </p>
          <p className="py-3">
            For over 200 years, our parent company in Spain has been a beacon of
            security, protecting families like yours from life's storms. Now,
            Medic brings that same legacy of care and expertise to Malaysia,
            tailored specifically for the needs of our vibrant community.
          </p>
          <p className="py-3"><u>Because we care, we offer:</u></p>
          <div className="border-2 rounded-lg py-3 px-3">
          <p className="py-3">
            Affordable plans: We understand that every ringgit counts. That's
            why our plans are designed to be accessible, so you can get the
            peace of mind you deserve without breaking the bank.
          </p>
          <p className="py-3">
            Transparent coverage: No hidden clauses, no confusing jargon. We
            believe in clear, honest communication, so you always know exactly
            what you're covered for.
          </p>
          <p className="py-3">
            Caring claims process: Life can be stressful enough when facing an accident or illness.
            We ensure a smooth and compassionate claims process to help you get back on your feet faster.
          </p>
          <p className="py-3">
            Community-focused support: From free health checkups to financial literacy workshops,
            we're here to empower you, not just insure you. We want to see our community thrive.
          </p>
          <p className="py-3">
            Don't let unforeseen hardship dim your brighter days. With Medic, you can face the 
            future with confidence, knowing that no matter what life throws your way, 
            you have a caring community standing by your side.
          </p>
          <p className="py-3">
            Ready to secure your peace of mind? Contact one of our friendly agents today for a 
            personalized consultation. Let's chat about your needs and find the perfect plan to 
            keep you and your loved ones safe. Together, we can navigate life's uncertainties with 
            strength and support.
          </p>
         
          </div>
          <p className="text-center">
            <label className="italic font-bold">
              Remember, at Medic, you're not just a policyholder, you're part of the family.
              </label>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

function Spacing() {
  return <div className="pt-14 pb-5"></div>;
}
