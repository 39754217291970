import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const autoplayOptions = {
  stopOnInteraction: false,
  delay: 10000
}

export default function HomeMedicalBenefit(){
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    containScroll: "trimSnaps",
    loop: true
  },
  [Autoplay(autoplayOptions)]
  );
  
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaApi.plugins().autoplay?.reset();
  },[]);

  useEffect(() => {
    if(!emblaApi){
      return;
    }
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="container mx-auto max-w-screen-xl px-5 lg:px-10 my-28">
      <div className="relative">
        <div ref={emblaRef} className="overflow-hidden lg:mx-10">
          <div className="flex ">
            <div
                  key="1"
                  className="flex items-center justify-center flex-shrink-0 w-full lg:w-1/3 h-80 lg:px-4 border border-black border-10"
                >
                  <div className="flex flex-col gap-4">
                    <h1 className="text-md lg:text-2xl text-center italic">
                        MedX Plus Plan 1
                    </h1>
                    <div>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Overall Annual Limit : RM 3,500,000
                      </p>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Room & Board : RM 650
                      </p>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Outpatient Benefits : Full Reimbursement
                      </p>
                    </div>
                  </div>
            </div>
            <div
                  key="2"
                  className="flex items-center justify-center flex-shrink-0 w-full lg:w-1/3 h-80 lg:px-4 border border-black border-10"
                >
                  <div className="flex flex-col gap-4">
                    <h1 className="text-md lg:text-2xl text-center italic">
                        MedX Plus Plan 2
                    </h1>
                    <div>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Overall Annual Limit : RM 2,000,000
                      </p>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Room & Board : RM 400
                      </p>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Outpatient Benefits : Full Reimbursement
                      </p>
                    </div>
                  </div>
            </div>
            <div
                  key="3"
                  className="flex items-center justify-center flex-shrink-0 w-full lg:w-1/3 h-80 lg:px-4 border border-black border-10"
                >
                  <div className="flex flex-col gap-4">
                    <h1 className="text-md lg:text-2xl text-center italic">
                        MedX Plus Plan 3
                    </h1>
                    <div>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Overall Annual Limit : RM 1,500,000
                      </p>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Room & Board : RM 200
                      </p>
                      <p className="text-sm lg:text-md text-center font-bold">
                      Outpatient Benefits : Full Reimbursement
                      </p>
                    </div>
                  </div>
            </div>
          </div>
          <div className="absolute bottom-5 w-full flex justify-center gap-4">
              <button
                key='1'
                className={`h-2 w-2 rounded-full ${
                  '1' === selectedIndex ? "bg-black" : "bg-gray-300"
                }`}
                onClick={() => {
                  emblaApi?.scrollTo(0);
                  emblaApi?.plugins().autoplay?.reset();
                }}
              >
                <span className="sr-only">{`Select slide 1`}</span>
              </button>
              <button
                key='2'
                className={`h-2 w-2 rounded-full ${
                  '2' === selectedIndex ? "bg-black" : "bg-gray-300"
                }`}
                onClick={() => {
                  emblaApi?.scrollTo(1);
                  emblaApi?.plugins().autoplay?.reset();
                }}
              >
                <span className="sr-only">{`Select slide 2`}</span>
              </button>
              <button
                key='3'
                className={`h-2 w-2 rounded-full ${
                  '3' === selectedIndex ? "bg-black" : "bg-gray-300"
                }`}
                onClick={() => {
                  emblaApi?.scrollTo(2);
                  emblaApi?.plugins().autoplay?.reset();
                }}
              >
                <span className="sr-only">{`Select slide 3`}</span>
              </button>
        </div>
        </div>
      </div>
    </div>
  );


}