import React, { useEffect, useState } from "react";
import Card from "../../components/dashboard/card/Card";
import CardHeader from "../../components/dashboard/card/Card.header";
import Paragraph from "../../components/dashboard/typography/Paragraph";
import Button from "../../components/dashboard/button/Button";
import { Link } from "react-router-dom";
import usePersonalInformation from "../../hooks/zustand/usePersonalInformation";
import ButtonLp from "../../components/ButtonLp";
import toRinggit from "../../lib/currencyFormatter";


export default function Dashboard() {
  const userInfo = usePersonalInformation();
  const [status, setStatus] = useState(true);
  const policies= userInfo.user.policies;

  useEffect(() => {
    if (userInfo?.user.status === "active") {
      //setAttempt(0);
      const attemptPayload = {
        suspendedAttempts: 0,
      };
      const res = userInfo.updateUserSuspendedAttempts(attemptPayload);
    }

    if (
      userInfo?.user.status === "suspend" &&
      userInfo?.user.suspendedAttempts >= 3
    ) {
      setStatus("suspend");
    } else {
      setStatus(
        userInfo?.user.status !== "suspend" ? userInfo?.user.status : "active"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user.status]);

  const onClick_moreinfo = (mobileNo, message) => {
    const url = `https://api.whatsapp.com/send?phone=${mobileNo}&text=${encodeURIComponent(message)}`;
    window.open(url, 'blank');
  }
  
  return (
    <div>
      <Paragraph
        size="large"
        color="white"
        className="font-semibold flex items-center space-x-2"
      >
        Dashboard
      </Paragraph>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-full lg:h-card-height gap-5 py-4 lg:py-0">
          <Card className="flex justify-between flex-col space-y-6">
            {/* MIDDLE CARD */}
            <div className="w-full h-max">
            <CardHeader leftTitle="PROFILE" color="black" />
              <div className="flex flex-row ">
                <div className="p-2  w-1/3">Name : </div>
                <div className="p-2 col-span-1 w-2/3">{userInfo.user.fullName}</div>
              </div>
              <div className="flex flex-row ">
                <div className="p-2 w-1/3">MyCard No. :</div>
                <div className="p-2 w-2/3">{userInfo.user.icno}</div>
              </div>
              <div className="flex flex-row ">
                <div className="p-2 w-1/3">Mobile :</div>
                <div className="p-2 w-1/3">{userInfo.user.phoneNumber}</div>
              </div>
              <div className="flex flex-row ">
                <div className="p-2 w-1/3">Email :</div>
                <div className="p-2 w-1/3">{userInfo.user.email}</div>
              </div>
              <div className="flex flex-row ">
                <div className="p-2 w-1/3">Address :</div>
                <div className="p-2 w-2/3">
                  {userInfo.user.address +
                    ", " +
                    userInfo.user.city +
                    ", " +
                    userInfo.user.postcode +
                    ", " +
                    userInfo.user.state +
                    ", " +
                    userInfo.user.country}
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="p-2 w-1/3">Professional :</div>
                <div className="p-2 w-2/3">{userInfo.user.professional}</div>
              </div>
              <div className="flex flex-row ">
                <div className="p-2 w-1/3">Employer :</div>
                <div className="p-2 w-2/3">{userInfo.user.employer}</div>
              </div>
              <div className="w-full rounded-lg ">
                <Link to="/Dashboard/profile-information">
                  <Button
                    radius="full"
                    className="py-2 text-white text-sm font-semibold"
                  >
                    Edit Profile
                  </Button>
                </Link>
              </div>
            </div>
          </Card>
          <Card backgroundColor="secondary">
            <CardHeader leftTitle="POLICY PROFILE" color="black" />
            <div className="overflow-y-auto">
            {
               policies ? (
                policies.map((item)=> (
                <div className=" py-2 ">
                  <div className="border p-4">
                      <heading className="underline py-1">
                        {item.product.name}
                      </heading>
                      <div className="flex flex-row">
                        <div className="w-1/3 font-bold">Policy Name : </div>
                        <div className="w-2/3">{item.policyName}</div>
                      </div>
                      <div className="flex flex-row">
                        <div className="w-1/3 font-bold">Annual Limit : </div>
                        <div className="w-2/3">{ toRinggit(item.annualCoverage)}</div>
                      </div>
                      <div className="grid grid-cols-1">
                        <heading className="underline py-1">Life Insured</heading>
                        <div className="flex flex-row">
                          <div className="w-1/3 font-bold" >Name : </div>
                          <div className="w-2/3">{item.beneficiaryName}</div>
                        </div>
                        <div className="flex flex-row">
                          <div className="w-1/3 font-bold">MyCard No : </div>
                          <div className="w-2/3">{item.beneficiaryIdNo}</div>
                        </div>
                      </div>
                      <div className="flex flex-auto mt-3  justify-end">
                        <Link to={`/Dashboard/policy-contract/${item._id}`} >
                          <Button
                            className="w-full text-center p-2 text-white"
                          > View Detail</Button>
                        </Link>
                      </div>
                      
                  </div>
                </div>
              ))):("<div>Loading policies...</div>")
           
            }
            </div>
          </Card>
        </div>
      </div>
      <div className="my-12 hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  h-72 gap-5 py-4 lg:py-0">
        <Card className="flex justify-between flex-col space-y-6">
              <div className="flex justify-center font-semibold" >
                  <div>
                    <h2 className="uppercase">Life</h2>
                  </div>
              </div>
              <div>
                <div className="flex justify-center">
                    <ButtonLp 
                      className="uppercase" 
                      onClick={()=>{onClick_moreinfo(
                        "6"+userInfo.user.adminInfo.phoneNumber,
                        'I’m interesedt in Life Insurance')}}
                      >More Info
                    </ButtonLp>
                  </div>
              </div>
          </Card>
          <Card className="flex justify-between flex-col space-y-6">
              <div className=" flex justify-center font-semibold" >
                  <div>
                    <h2 className="uppercase">Critical Illness</h2>
                  </div>
              </div>
              <div>
                <div className="flex justify-center">
                    <ButtonLp 
                      className="uppercase" 
                      onClick={()=>{onClick_moreinfo(
                        "6"+userInfo.user.adminInfo.phoneNumber, 
                        'I’m interested in Critical Illness')}}
                      >More Info
                    </ButtonLp>
                  </div>
              </div>
          </Card>
          <Card className="flex justify-between flex-col space-y-6">
              <div className="flex justify-center font-semibold" >
                  <div>
                    <h2 className="uppercase">Medical Card</h2>
                  </div>
              </div>
              <div>
                <div className="flex justify-center">
                  <ButtonLp 
                      className="uppercase" 
                      onClick={()=>{onClick_moreinfo(
                        "6"+userInfo.user.adminInfo.phoneNumber, 
                        'I’m interested in Medical Card')}}
                      >More Info
                    </ButtonLp>
                  </div>
              </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
