import useAuth from '../hooks/zustand/useAuth';
import usePersonalInformation from '../hooks/zustand/usePersonalInformation';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import useLocalStorage from '../hooks/useLocalStorage';

export default function AuthGuard({ children }) {
  const { auth } = useAuth((state) => ({ auth: state.auth }), shallow);
  const personalInformation = usePersonalInformation(
    (state) => ({ user: state.user, getData: state.getData }),
    shallow
  );
 
  const navigate = useNavigate();

  async function getPersonalInformation() {
    await personalInformation.getData();
    return;
  }
  // const attempt = useLocalStorage('withdrawalAttempt');
  const attempt = personalInformation.user.suspendedAttempts;
  // console.log("attempt", attempt);
  useEffect(() => {
    let isMounted = true;
    if (auth?.accessToken && auth?.role?.replace(/["']/g, '') !== 'user') {
      navigate('/dashboard/admin/user-list');
    }
    if (!auth.accessToken) {
      navigate('/sign-in');
    }
    if (personalInformation?.user?.status === 'suspend' && attempt >= 3) {
      navigate('/dashboard/withdraw');
    }
    if (
      auth.accessToken &&
      personalInformation.user._id === '' &&
      auth.role?.replace(/["']/g, '') === 'user'
    ) {
      getPersonalInformation().then(() => {
        if (!isMounted) return;
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.accessToken, personalInformation?.user._id, auth?.role]);

  return <div>{children}</div>;
}
