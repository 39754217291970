import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, RHFInput, RHFLabel } from "../../components/RHForm";
import ButtonLp from "../../components/ButtonLp";
import { enqueueSnackbar } from "notistack";
import OTPInput from "react-otp-input";
import { generateRandomNumber } from "../../utils/utils";
import useAuth from "../../hooks/zustand/useAuth";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [submited, setSubmited] = useState(false);
  const [isRequestOtp, setIsRequestOtp] = useState(true);
  const [otp, setOtp] = useState("");
  let [randOtp, setRandOtp] = useState();
  const phoneNumber = window.phoneNumber;
  const countryCode = window.countryCode;
  const schema = yup.object().shape({
    password: yup
      .string()
      .required("New Password is required")
      .min(6, "Minimum 6 characters")
      .max(18, "Maximum 18 characters"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .min(6, "Minimum 6 characters")
      .max(18, "Maximum 18 characters"),
  });

  const defaultValues = {
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, handleSubmit, reset } = methods;
  const values = watch();

  useEffect(() => {
    if (!window.phoneNumber) {
      navigate("/forgot-password");
    }
  }, [window.phoneNumber]);

  const sendSms = async (mobileNumber, textMessage) => {
    try {
      const payload = {
        mobileNo: mobileNumber,
        textMessage: textMessage,
      };

      const response = await Auth.sendOtp(payload);
      return response;
    } catch (error) {
      return { error: error };
    }
  };

  const btnRequestOtp_onclicked = async () => {
    if (isRequestOtp === true) setIsRequestOtp(false);
    else {
      setIsRequestOtp(true);
    }

    randOtp = String(generateRandomNumber(10000, 999999)).padStart(6, "0");
    const mobileNumber = countryCode + phoneNumber;
    setRandOtp(randOtp);
    const textMessage = `Your One Time Pin (OTP) is ${randOtp}. This pin will expire in 5 minutes. Do not share with any third part`;
    await sendSms(mobileNumber, textMessage);
  };

  const Auth = useAuth();
  const onSubmit = async () => {
    try {
      if (otp.length < 6) {
        setSubmited(false);
        enqueueSnackbar("Invalid OTP", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return false;
      }

      if (values.password !== values.confirmPassword) {
        setSubmited(false);
        enqueueSnackbar("Password and confirm password are not matching", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return false;
      }

      if (otp !== randOtp || otp.length < 6) {
        setSubmited(false);
        enqueueSnackbar("Invalid OTP", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return false;
      }

      const payload = {
        phoneNumber: phoneNumber,
        password: values.password,
      };
      const response = await Auth.resetPassword(payload);
      if (response.modifiedCount > 0) {
        enqueueSnackbar("Password reset successful", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        navigate("/sign-in");
      }
    } catch (error) {
      enqueueSnackbar("An error occured", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setSubmited(false);
    }
  };

  return (
    <div
      className="w-full min-h-screen flex items-center"
      style={{
        backgroundImage: "url('/images/login-bg.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full px-5 overflow-hidden">
        <div className="w-full lg:w-[30rem] bg-white overflow-hidden rounded-lg shadow-lg px-5 lg:px-10 py-10 lg:py-14 mx-auto">
          <div className="flex flex-col items-center">
            <img
              src={process.env.REACT_APP_LOGO_PATH}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-36 lg:w-40 object-contain object-center"
            />
            <h1 className="text-xl lg:text-2xl font-semibold mt-10 mb-2">
              Reset New Password
            </h1>
          </div>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 lg:gap-8 w-full mx-auto">
              <div id="recaptcha-container" />
              <RHFInput
                type="password"
                name="password"
                label="New Password"
              ></RHFInput>
              <RHFInput
                type="password"
                name="confirmPassword"
                label="Confirm password"
              ></RHFInput>
              <RHFLabel label="One Time Password (OTP)"></RHFLabel>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => (
                  <input {...props} className="!w-10 rounded-lg" />
                )}
                containerStyle="space-x-4 w-full items-center justify-center"
                inputType="number"
              />
              <div
                className={`inline-flex justify-end ${
                  isRequestOtp ? "visible" : "invisible"
                }`}
              >
                <ButtonLp className="w-36" onClick={btnRequestOtp_onclicked}>
                  Request OTP
                </ButtonLp>
              </div>
              <RHFLabel
                invisible={isRequestOtp}
                label="OTP had been sms to your mobile."
              ></RHFLabel>

              <ButtonLp
                type="submit"
                className="w-40 mx-auto"
                loading={submited}
              >
                Continue
              </ButtonLp>
            </div>
          </FormProvider>
          <div className="mt-5 text-center">
            <Link to="/" className="font-semibold text-blue-500 text-sm">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
