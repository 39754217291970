import Paragraph from '../typography/Paragraph';

export default function CardHeader({ leftTitle, rightTitle, rightTitleIcon, color }) {
  return (
    <div className='flex justify-between'>
      <Paragraph color={color?? 'white'} size='large' className='font-semibold'>
        {leftTitle}
      </Paragraph>
      <div className='flex items-center'>
        <Paragraph
          color={color ?? 'white'}
          size='large'
          className='font-semibold flex items-center space-x-2'
        >
          <span>{rightTitle}</span>
          {rightTitleIcon}
        </Paragraph>
      </div>
    </div>
  );
}
