import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import useBankStore from '../../hooks/zustand/bank/useBankStore';
// components
import {
  FormProvider,
  RHFInput,
  RHFInputAddon,
  RHFCheckBox,
  RHFSelect,
} from '../../components/RHForm';
import ButtonLp from '../../components/ButtonLp';
import useAuth from '../../hooks/zustand/useAuth';
import { useNavigate } from 'react-router-dom';
import { handleRegisterResponse } from '../../lib/api/handleApiResponse';
import { enqueueSnackbar } from 'notistack';

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  // lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .matches(/^\d+$/, 'Number only!')
    .min(9, 'Minimum 9 digit numbers')
    .max(13, 'Maximum 13 digit numbers'),
  email: yup.string().email(),
  password: yup
    .string()
    .min(6, 'Minimum 6 digit characters!')
    .max(32, 'Maximum 32 digit characters!'),
  referralCode: yup.string(),
  confirm: yup.bool().required('Please confirm this statement'),
});

const bankInformationSchema = yup.object().shape({
  bankHolder: yup.string().required('Account holder is required!'),
  bankAccount: yup.string().required('Bank account number is required!'),
  bankName: yup.string().required('Bank name is required!'),
});

const defaultValues = {
  firstName: '',
  //lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  referralCode: '',
  confirm: undefined,
};

const SignUp = () => {
  const auth = useAuth();
  const bank = useBankStore();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const closeButtonRef = useRef(null);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const bankInformationMethods = useForm({
    resolver: yupResolver(bankInformationSchema),
  });

  const {
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const {
    handleSubmit: handleSubmitBank,
    formState: { isSubmitting: isSubmittingBank },
  } = bankInformationMethods;

  const values = watch();

  async function initialize(page) {
    await bank.getData('', page, 100);
  }

  useEffect(() => {
    initialize(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setStep(2);
  };

  function onError(error) {
    enqueueSnackbar(`${error}`, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  }

  const onSubmitBank = async (data) => {
    const payload = { ...values, ...data };
    const res = await auth.register(payload);
    handleRegisterResponse(res, setStep, onError, setError, navigate);
  };

  return (
    <>
      <div
        className='w-full min-h-screen flex items-center'
        style={{
          backgroundImage: "url('/images/login-bg.jpg')",
          backgroundSize: 'cover',
          // backgroundPosition: "center",
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className='w-full lg:px-5 md:px-3 sm:px-3 py-10'>
          <div className='w-full lg:w-[40rem] bg-white overflow-hidden rounded-lg shadow-lg p-5 lg:px-10 py-10 lg:py-14 mx-auto'>
            <div className='flex flex-col items-center'>
              <img
                src='/images/logo-dark.png'
                //src='images/Produk-Kewangan-Black-Logo.png'
                alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
                className='w-36 lg:w-40 object-contain object-center'
              />
              <h1 className='text-xl lg:text-2xl font-semibold mt-10 mb-2'>
                Create an Account
              </h1>
              <h2 className='text-sm lg:text-md font-semibold text-gray-400 text-center mb-10'>
                {step === 1 ? (
                  <>
                    Already have an account?{' '}
                    <Link to='/sign-in' className='text-blue-500'>
                      Sign in here
                    </Link>
                  </>
                ) : (
                  'Last step before start !'
                )}
              </h2>
            </div>

            {step === 1 ? (
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4 lg:gap-8 w-full mx-auto'>
                <RHFInput name='firstName' label='Name As Per IC' />  
                  {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                    <RHFInput name='firstName' label='First Name' />
                    <RHFInput name='lastName' label='Last Name' />
                  </div> */}
                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                    <RHFInputAddon
                      name='phoneNumber'
                      label='Phone'
                      addon='+60'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <RHFInput name='email' label='Email' />
                  </div>
                  <RHFInput name='password' label='Password' type='password' />
                  <RHFInput name='referralCode' label='Referral Code' />
                  <div className='flex items-center'>
                    <RHFCheckBox name='confirm'>
                      <button
                        type='button'
                        className='ml-2 text-sm font-medium text-blue-500'
                        onClick={() => setOpen(true)}
                      >
                        Confirming you are not a U.S. citizen or tax resident.
                      </button>
                    </RHFCheckBox>
                  </div>
                  <ButtonLp
                    type='submit'
                    className='w-40 mx-auto mt-5'
                    loading={isSubmitting}
                  >
                    Continue
                  </ButtonLp>
                </div>
              </FormProvider>
            ) : null}
            {step === 2 ? (
              <FormProvider
                methods={bankInformationMethods}
                onSubmit={handleSubmitBank(onSubmitBank, (e) => console.log(e))}
              >
                <div className='flex flex-col gap-4 lg:gap-8 w-full mx-auto'>
                  <RHFSelect
                    name='bankName'
                    label='Bank Name'
                    listSelect={bank.data?.map((a) => {
                      return { label: a.bankId };
                    })}
                  />
                  <RHFInput
                    name='bankAccount'
                    label='Bank Account Number'
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <RHFInput name='bankHolder' label='Account Holder Name' />
                  <ButtonLp
                    type='submit'
                    className='w-40 mx-auto mt-5'
                    loading={isSubmittingBank}
                  >
                    Submit
                  </ButtonLp>
                </div>
              </FormProvider>
            ) : null}
            <div className='mt-4 text-center'>
              <Link to='/' className='font-semibold text-blue-500 text-sm'>
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={closeButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-5'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                  <div className='bg-white p-5 sm:p-6 sm:pb-4'>
                    <div className='sm:flex sm:items-start'>
                      <div className='mt-3'>
                        <Dialog.Title
                          as='h3'
                          className='font-semibold leading-6'
                        >
                          Confirming you are not a U.S. citizen or tax resident
                        </Dialog.Title>
                        <div className='text-sm mt-10'>
                          <p>
                            By giving this statement, I explicitly declare and
                            confirm that:
                          </p>
                          <ul className='list-disc ml-10 my-5'>
                            <li>I am not a U.S. citizen or resident</li>
                            <li>
                              I do not directly or indirectly own more than 10%
                              of shares/voting rights/interest of the U.S.
                              residents and/or do not control U.S. citizens or
                              residents by other means
                            </li>
                            <li>
                              I am not under the direct or indirect ownership of
                              more than 10% of shares/voting rights/interest
                              and/or under the control of U.S. citizen or
                              resident exercised by other means
                            </li>
                            <li>
                              I am not affiliated with U.S. citizens or
                              residents in terms of Section 1504(a) of FATCA
                            </li>
                            <li>
                              I am aware of my liability for making a false
                              declaration.
                            </li>
                          </ul>
                          <p>
                            For the purposes of this statement, all U.S.
                            dependent countries and territories are equalled to
                            the main territory of the USA. I commit to defend
                            and hold harmless ${process.env.REACT_APP_PROJECT_TITLE}, its directors and officers
                            against any claims arising from or related to any
                            breach of my statement hereby.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-gray-50 p-5 flex justify-end'>
                    <button
                      type='button'
                      className='rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                      onClick={() => setOpen(false)}
                      ref={closeButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SignUp;
