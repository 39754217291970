const formatDate = (x) => (
    new Date(x).toLocaleDateString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
    }).replaceAll('/', '-')
);

const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1 )) + min;
}

module.exports = {
    formatDate,
    generateRandomNumber
}